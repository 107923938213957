.auth-page {
  background-color: #000;
  width: 100%;
  min-height: 100vh;
  background-repeat: no-repeat !important;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  resize: vertical;
}

.auth-nav {
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(0, 0, 0, 0.3),
    black
  );
  text-align: end;
  padding: 0.4rem 2rem;
  margin-bottom: 50px !important;
  a {
    color: #e5d92fc2;
    font-size: 0.9rem;
    &:hover {
      color: #e5d92f;
    }
  }
}

.auth-page.for-signup {
  padding-bottom: 50px;
}

.auth-page-content {
  background-color: #000;
  width: 80%;
  margin: auto;
  border-radius: 15px;
  resize: vertical;
  overflow: auto;
}
.image-logo-round {
  width: 80px;
  height: 80px;
  position: absolute;
  margin-top: -41px;
  left: 47%;
}

.auth-page-content h3 {
  margin-top: 24px;
  font-size: 24px;
  position: relative;
}
.auth-page-content h5 {
  padding-top: 0px !important;
  font-size: 18px;
}

// SIGN-UP NAVIGATION
.signup-form-nav {
  width: 90%;
}
.signup-form-nav span.col-4 {
  padding-left: 0px !important;
  &:last-child {
    padding-right: 0px;
  }
}

.signup-form-nav span.form-nav-indicator {
  display: inline-block;
  font-size: 15px;
  border-bottom: 4px solid $primary;
  width: 100%;
  padding-bottom: 3px;
}

.signup-form-nav span.form-nav-indicator span.number-indicator {
  font-size: 12px;
  font-weight: normal !important;
  border: 1px solid $primary;
  border-radius: 50%;
  padding: 0px 5px;
}

.signup-form-nav
  span.form-nav-indicator
  span.number-indicator.number-indicator-border {
  border: none;
}
.check-success {
  vertical-align: top;
}

.signup-form-nav span.disable-indicator {
  opacity: 0.4;
}

// REGISTRATION FORM

.register-content {
  width: 90%;
}

.register-content .col-6.div-1,
.register-content .col-6.div-2 {
  padding-left: 0px !important;
  &:last-child {
    padding-right: 0px !important;
  }
}

.auth-success {
  margin-top: 23%;
}
.auth-form p.text-white {
  font-size: 16px;
}
.bank-info-form {
  margin-top: 60px !important;
}

.form-div {
  min-height: 160px;
}

// FORM IMAGES
.form-img-div {
  width: 330px;
  height: 325px;
  margin: auto;
}
.right-form-image {
  width: 100%;
  height: 300px;
}
.form-cover-img-div {
  width: 320px;
  height: 300px;
  margin: 10% auto 0 auto;
}
.vector-1-image {
  width: 100%;
  height: 300px;
}
.sm-form-cover-div {
  position: absolute;
  top: 98px;
  left: 28%;
  width: 200px;
  height: 200px;
}
.phone-success-image {
  width: 100%;
  height: 200px;
}

.input-group-prepend.payment-info-grp .input-group-text {
  background-color: $dark;
  border: none !important;
}

// Image Preview
.img-preview,
.art-info-pic-preview {
  border-radius: 10px;
}
.img-preview .preview-pic {
  width: 280px;
  height: 200px;
  object-fit: cover;
}
.img-preview .initial-pic {
  width: 180px;
  height: 180px;
  object-fit: cover;
}
.file-label {
  height: 38px;
  width: 140px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: auto;
}

// CHECK BOX
.signup-checkBox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.signup-checkBox .custom-control-label {
  font-size: 13px;
  padding-top: 5px;
  padding-left: 3px;
  font-weight: bold;
}
.signup-checkBox .custom-control-label::before {
  width: 1rem;
  height: 1rem;
  background-color: transparent !important;
  border-width: 1.5px !important;
  border-color: $primary !important;
  color: $primary !important;
  margin-top: 2px;
  cursor: pointer !important;
}
.signup-checkBox .custom-control-label::after {
  width: 1rem;
  height: 1rem;
  background: no-repeat 50% / 50% 50%;
}

.signup-checkBox .custom-checkbox .custom-control-label::before {
  border-radius: 20%;
  cursor: pointer !important;
}

.signup-checkBox .custom-checkbox .custom-control-input {
  cursor: pointer !important;
}

.signup-checkBox
  .custom-checkbox
  .custom-control-input:checked
  ~ .custom-control-label::after {
  content: '';
  padding-left: 1px;
  padding-top: 20.5px;
  font-size: 12px !important;
  border-radius: 5px;
  cursor: pointer !important;
}

// File input
.custom-file-label {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  border-width: 2px;
  border-color: $primary;
  height: 40px;
  cursor: pointer !important;
  padding: 0.428rem 0.75rem;
}

.custom-file-label::after {
  background-color: $lightSecondary;
  color: #fff;
  height: 40px;
  cursor: pointer !important;
  padding: 0.428rem 0.75rem;
}

.img-preview {
  width: 80%;
  height: 240px;
  margin: auto;
}
.art-info-pic-preview {
  width: 92%;
  height: 240px;
  margin: auto;
}
.id-form .img-preview {
  width: 80%;
  height: 270px !important;
  margin: auto;
}

.img-preview-set {
  width: 100%;
  height: 190px;
  word-break: break-all;
}

.img-preview img,
.img-preview-set img,
.art-info-pic-preview img {
  object-fit: cover;
}

.preview-img {
  margin: auto !important;
}
