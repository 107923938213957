@import './variables';
@import '~bootstrap/scss/bootstrap.scss';
@import 'Auth';
@import 'Sidebar';
@import 'Modals';
@import 'TagInput';
@import 'Loading';
@import 'Onboarding.scss';

html,
body {
  font-family: 'Signika', sans-serif;
}

.hr {
  margin-top: 0.5rem;
  width: 150%;
  margin-left: -25%;
  margin-bottom: 0.5rem;
  border-top: 2px solid #ffffff;
}

a:hover {
  text-decoration: none;
}

.container {
  background-color: #000000;
  height: 100vh;
  overflow-y: scroll;
}
input:disabled {
  cursor: not-allowed !important;
}
.wrapper {
  background-color: #000000;
  height: 100vh;
}
.disable-btn {
  pointer-events: none !important;
  cursor: none !important;
  opacity: 0.6 !important;
}
.bg-light-dark {
  background-color: #585858 !important;
}

.text-light-dark {
  color: #585858 !important;
}

.xxlarge-text {
  font-size: xx-large;
}

.xlarge-text {
  font-size: x-large;
}

.larger-text {
  font-size: larger;
}

.large-text {
  font-size: large;
}

.medium-text {
  font-size: medium;
}

.small-text {
  font-size: small;
}

.smaller-text {
  font-size: smaller;
}

.yellow {
  color: $primary;
}

.white {
  color: #ffffff;
}

.table > tbody > tr > td {
  vertical-align: middle;
}
table {
  thead > tr > th {
    border: none !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #3a3a3a;
}

::-webkit-scrollbar-thumb {
  background: #e5da2f;
}

::-webkit-scrollbar-thumb:hover {
  background: #e5d92fc7;
}

.searchInput {
  background-color: transparent !important;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid $dark;
  font-size: large;
  &:focus {
    border: none;
    border-bottom: 2px solid $dark;
    outline: none !important;
    box-shadow: none !important;
    color: white;
  }
  &::placeholder {
    color: rgb(161, 161, 161);
  }
}

.formInput {
  background-color: #585858 !important;
  border: 1.3px solid $lightSecondary;
  font-size: medium;
  color: white !important;
  &:focus {
    border: 1.5px solid $lightSecondary;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
  &::placeholder {
    color: white !important;
    font-size: medium;
  }
}

.formSelect {
  background-color: #585858 !important;
  font-size: medium !important;
  color: white !important;
  &:focus {
    border: 1.5px solid $lightSecondary;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.8) !important;
    color: white !important;
  }
  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
}

.provider-initials-small {
  background-color: #585858;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.graph-Loader {
  position: relative;
  top: -16em;
}

button:disabled {
  cursor: not-allowed;
}

.alert-top-right {
  @extend .alert-dismissible;
  @extend .fade;
  @extend .show;
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1051;
}

@mixin border-widths {
  @for $i from 1 through 10 {
    .border-#{$i} {
      border-width: #{$i * 1}px !important;
    }
  }
}

@include border-widths();
