.modal-form-div {
  width: 80%;
  margin: auto;
}

.modal-content {
  margin-top: 28%;
}
.cover-photo-mo-content {
  margin-top: 15%;
}

.artist-modal {
  width: 80%;
}

.modal {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.img-preview {
  width: 80%;
  height: 240px;
  margin: auto;
}

.img-preview-set {
  width: 100%;
  height: 180px;
  word-break: break-all;
}

.preview-img {
  margin: auto !important;
}

input[type='file'] {
  display: none;
}

.file-label,
.file-label-set {
  // background-color: $primary !important;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 16px;
  margin: auto;
}

.file-label {
  height: 38px;
  width: 140px;
}

.file-label-set {
  height: 30px;
  width: 110px;
}

.song-indicator {
  height: 50%;
  padding-top: 25%;
}

.close-button {
  font-size: 26px !important;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 2% !important;
  top: 3.5% !important;
}

.modal-header {
  border-bottom: none !important;
}

.payout-modal-content {
  margin-top: 10vh;
  height: 80vh;
  overflow-y: scroll;
}
